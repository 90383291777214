import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
    <div className="copyright">
      <p>Copyright © Designed &amp; Developed by <a href="#" target="_blank">Xonier</a> 2022</p>
    </div>
  </div>
  )
}

export default Footer