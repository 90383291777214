import React from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';

const ServiceDetail = () => {
  return (
    <>
       <Header />
       <Footer />
    </>
  )
}

export default ServiceDetail